export const MEMBER_LOGIN_REQUEST = "MEMBER_LOGIN_REQUEST";
export const MEMBER_LOGIN_SUCCESS = "MEMBER_LOGIN_SUCCESS";
export const MEMBER_LOGIN_FAIL = "MEMBER_LOGIN_FAIL";
export const MEMBER_LOGOUT = "MEMBER_LOGOUT";

export const MEMBER_REGISTER_REQUEST = "MEMBER_REGISTER_REQUEST";
export const MEMBER_REGISTER_SUCCESS = "MEMBER_REGISTER_SUCCESS";
export const MEMBER_REGISTER_FAIL = "MEMBER_REGISTER_FAIL";

export const MEMBER_DETAILS_REQUEST = "MEMBER_DETAILS_REQUEST";
export const MEMBER_DETAILS_SUCCESS = "MEMBER_DETAILS_SUCCESS";
export const MEMBER_DETAILS_FAIL = "MEMBER_DETAILS_FAIL";

export const MEMBER_PUBLIC_DETAILS_REQUEST = "MEMBER_PUBLIC_DETAILS_REQUEST";
export const MEMBER_PUBLIC_DETAILS_SUCCESS = "MEMBER_PUBLIC_DETAILS_SUCCESS";
export const MEMBER_PUBLIC_DETAILS_FAIL = "MEMBER_PUBLIC_DETAILS_FAIL";

export const BLACKBELT_LIST_REQUEST = "BLACKBELT_LIST_REQUEST";
export const BLACKBELT_LIST_SUCCESS = "BLACKBELT_LIST_SUCCESS";
export const BLACKBELT_LIST_FAIL = "BLACKBELT_LIST_FAIL";

export const FORMER_BLACKBELT_LIST_REQUEST = "FORMER_BLACKBELT_LIST_REQUEST";
export const FORMER_BLACKBELT_LIST_SUCCESS = "FORMER_BLACKBELT_LIST_SUCCESS";
export const FORMER_BLACKBELT_LIST_FAIL = "FORMER_BLACKBELT_LIST_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LIST_MEMBERS_REQUEST = "LIST_MEMBERS_REQUEST";
export const LIST_MEMBERS_SUCCESS = "LIST_MEMBERS_SUCCESS";
export const LIST_MEMBERS_FAIL = "LIST_MEMBERS_FAIL";
export const LIST_MEMBERS_RESET = "LIST_MEMBERS_RESET";

export const LIST_WELFARE_PUBLIC_REQUEST = "LIST_WELFARE_PUBLIC_REQUEST";
export const LIST_WELFARE_PUBLIC_SUCCESS = "LIST_WELFARE_PUBLIC_SUCCESS";
export const LIST_WELFARE_PUBLIC_FAIL = "LIST_WELFARE_PUBLIC_FAIL";
export const LIST_WELFARE_PUBLIC_CLEAR = "LIST_WELFARE_PUBLIC_CLEAR";

export const LIST_WELFARE_MEMBER_REQUEST = "LIST_WELFARE_MEMBER_REQUEST";
export const LIST_WELFARE_MEMBER_SUCCESS = "LIST_WELFARE_MEMBER_SUCCESS";
export const LIST_WELFARE_MEMBER_FAIL = "LIST_WELFARE_MEMBER_FAIL";

export const MEMBER_DELETE_REQUEST = "MEMBER_DELETE_REQUEST";
export const MEMBER_DELETE_SUCCESS = "MEMBER_DELETE_SUCCESS";
export const MEMBER_DELETE_FAIL = "MEMBER_DELETE_FAIL";

export const EDIT_MEMBER_REQUEST = "EDIT_MEMBER_REQUEST";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_FAIL = "EDIT_MEMBER_FAIL";
export const EDIT_MEMBER_RESET = "EDIT_MEMBER_RESET";

export const MEMBER_ATTRECORD_REQUEST = "MEMBER_ATTRECORD_REQUEST";
export const MEMBER_ATTRECORD_SUCCESS = "MEMBER_ATTRECORD_SUCCESS";
export const MEMBER_ATTRECORD_FAIL = "MEMBER_ATTRECORD_FAIL";
