export const DD_UPDATE_REQUEST = "DD_UPDATE_REQUEST";
export const DD_UPDATE_SUCCESS = "DD_UPDATE_SUCCESS";
export const DD_UPDATE_FAIL = "DD_UPDATE_FAIL";

export const DD_CANCEL_REQUEST = "DD_CANCEL_REQUEST";
export const DD_CANCEL_SUCCESS = "DD_CANCEL_SUCCESS";
export const DD_CANCEL_FAIL = "DD_CANCEL_FAIL";
export const DD_CANCEL_CLEAR = "DD_CANCEL_CLEAR";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";

export const CREATE_DD_PAYMENT_REQUEST = "CREATE_DD_PAYMENT_REQUEST";
export const CREATE_DD_PAYMENT_SUCCESS = "CREATE_DD_PAYMENT_SUCCESS";
export const CREATE_DD_PAYMENT_FAIL = "CREATE_DD_PAYMENT_FAIL";
export const CREATE_DD_PAYMENT_RESET = "CREATE_DD_PAYMENT_RESET";

export const PAYMENT_CANCEL_REQUEST = "PAYMENT_CANCEL_REQUEST";
export const PAYMENT_CANCEL_SUCCESS = "PAYMENT_CANCEL_SUCCESS";
export const PAYMENT_CANCEL_FAIL = "PAYMENT_CANCEL_FAIL";
export const PAYMENT_CANCEL_CLEAR = "PAYMENT_CANCEL_CLEAR";
