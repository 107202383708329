export const TRAINING_SESSION_REQUEST = "TRAINING_SESSION_REQUEST";
export const TRAINING_SESSION_SUCCESS = "TRAINING_SESSION_SUCCESS";
export const TRAINING_SESSION_FAIL = "TRAINING_SESSION_FAIL";

export const TRAINING_SESSION_ID_REQUEST = "TRAINING_SESSION_ID_REQUEST";
export const TRAINING_SESSION_ID_SUCCESS = "TRAINING_SESSION_ID_SUCCESS";
export const TRAINING_SESSION_ID_FAIL = "TRAINING_SESSION_ID_FAIL";

export const MY_CLASS_LIST_REQUEST = "MY_CLASS_LIST_REQUEST";
export const MY_CLASS_LIST_SUCCESS = "MY_CLASS_LIST_SUCCESS";
export const MY_CLASS_LIST_FAIL = "MY_CLASS_LIST_FAIL";

export const MEMBER_CLASS_LIST_REQUEST = "MEMBER_CLASS_LIST_REQUEST";
export const MEMBER_CLASS_LIST_SUCCESS = "MEMBER_CLASS_LIST_SUCCESS";
export const MEMBER_CLASS_LIST_FAIL = "MEMBER_CLASS_LIST_FAIL";

export const ADD_CLASS_LIST_REQUEST = "ADD_CLASS_LIST_REQUEST";
export const ADD_CLASS_LIST_SUCCESS = "ADD_CLASS_LIST_SUCCESS";
export const ADD_CLASS_LIST_FAIL = "ADD_CLASS_LIST_FAIL";

export const DELETE_CLASS_LIST_REQUEST = "DELETE_CLASS_LIST_REQUEST";
export const DELETE_CLASS_LIST_SUCCESS = "DELETE_CLASS_LIST_SUCCESS";
export const DELETE_CLASS_LIST_FAIL = "DELETE_CLASS_LIST_FAIL";

export const SWITCH_CLASS_LIST_REQUEST = "SWITCH_CLASS_LIST_REQUEST";
export const SWITCH_CLASS_LIST_SUCCESS = "SWITCH_CLASS_LIST_SUCCESS";
export const SWITCH_CLASS_LIST_FAIL = "SWITCH_CLASS_LIST_FAIL";

export const TRAINING_SESSION_DELETE_REQUEST =
  "TRAINING_SESSION_DELETE_REQUEST";
export const TRAINING_SESSION_DELETE_SUCCESS =
  "TRAINING_SESSION_DELETE_SUCCESS";
export const TRAINING_SESSION_DELETE_FAIL = "TRAINING_SESSION_DELETE_FAIL";

export const TRAINING_SESSION_UPDATE_REQUEST =
  "TRAINING_SESSION_UPDATE_REQUEST";
export const TRAINING_SESSION_UPDATE_SUCCESS =
  "TRAINING_SESSION_UPDATE_SUCCESS";
export const TRAINING_SESSION_UPDATE_FAIL = "TRAINING_SESSION_UPDATE_FAIL";
export const TRAINING_SESSION_UPDATE_RESET = "TRAINING_SESSION_UPDATE_RESET";

export const TRAINING_SESSION_CREATE_REQUEST =
  "TRAINING_SESSION_CREATE_REQUEST";
export const TRAINING_SESSION_CREATE_SUCCESS =
  "TRAINING_SESSION_CREATE_SUCCESS";
export const TRAINING_SESSION_CREATE_FAIL = "TRAINING_SESSION_CREATE_FAIL";
export const TRAINING_SESSION_CREATE_RESET = "TRAINING_SESSION_CREATE_RESET";

export const TRAINING_SESSION_CANCEL_REQUEST =
  "TRAINING_SESSION_CANCEL_REQUEST";
export const TRAINING_SESSION_CANCEL_SUCCESS =
  "TRAINING_SESSION_CANCEL_SUCCESS";
export const TRAINING_SESSION_CANCEL_FAIL = "TRAINING_SESSION_CANCEL_FAIL";
export const TRAINING_SESSION_CANCEL_RESET = "TRAINING_SESSION_CANCEL_RESET";

export const WAITING_LIST_REQUEST = "WAITING_LIST_REQUEST";
export const WAITING_LIST_SUCCESS = "WAITING_LIST_SUCCESS";
export const WAITING_LIST_FAIL = "WAITING_LIST_FAIL";
export const WAITING_LIST_RESET = "WAITING_LIST_RESET";
