export const TRIAL_REGISTER_REQUEST = "TRIAL_REGISTER_REQUEST";
export const TRIAL_REGISTER_SUCCESS = "TRIAL_REGISTER_SUCCESS";
export const TRIAL_REGISTER_FAIL = "TRIAL_REGISTER_FAIL";
export const TRIAL_REGISTER_RESET = "TRIAL_REGISTER_RESET";

export const TRIAL_GET_REQUEST = "TRIAL_GET_REQUEST";
export const TRIAL_GET_SUCCESS = "TRIAL_GET_SUCCESS";
export const TRIAL_GET_FAIL = "TRIAL_GET_FAIL";
export const TRIAL_GET_RESET = "TRIAL_GET_RESET";

export const TRIAL_PAY_REQUEST = "TRIAL_PAY_REQUEST";
export const TRIAL_PAY_SUCCESS = "TRIAL_PAY_SUCCESS";
export const TRIAL_PAY_FAIL = "TRIAL_PAY_FAIL";

export const TRIAL_LIST_REQUEST = "TRIAL_LIST_REQUEST";
export const TRIAL_LIST_SUCCESS = "TRIAL_LIST_SUCCESS";
export const TRIAL_LIST_FAIL = "TRIAL_LIST_FAIL";
