export const FINANCIAL_LIST_REQUEST = "FINANCIAL_LIST_REQUEST";
export const FINANCIAL_LIST_SUCCESS = "FINANCIAL_LIST_SUCCESS";
export const FINANCIAL_LIST_FAIL = "FINANCIAL_LIST_FAIL";

export const FINANCIAL_UPDATE_REQUEST = "FINANCIAL_UPDATE_REQUEST";
export const FINANCIAL_UPDATE_SUCCESS = "FINANCIAL_UPDATE_SUCCESS";
export const FINANCIAL_UPDATE_FAIL = "FINANCIAL_UPDATE_FAIL";

export const MONTHLY_COSTS_LIST_REQUEST = "MONTHLY_COSTS_LIST_REQUEST";
export const MONTHLY_COSTS_LIST_SUCCESS = "MONTHLY_COSTS_LIST_SUCCESS";
export const MONTHLY_COSTS_LIST_FAIL = "MONTHLY_COSTS_LIST_FAIL";

export const MONTHLY_COSTS_UPDATE_REQUEST = "MONTHLY_COSTS_UPDATE_REQUEST";
export const MONTHLY_COSTS_UPDATE_SUCCESS = "MONTHLY_COSTS_UPDATE_SUCCESS";
export const MONTHLY_COSTS_UPDATE_FAIL = "MONTHLY_COSTS_UPDATE_FAIL";

export const MONTHLY_COSTS_DELETE_REQUEST = "MONTHLY_COSTS_DELETE_REQUEST";
export const MONTHLY_COSTS_DELETE_SUCCESS = "MONTHLY_COSTS_DELETE_SUCCESS";
export const MONTHLY_COSTS_DELETE_FAIL = "MONTHLY_COSTS_DELETE_FAIL";
