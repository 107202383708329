export const ATTENDANCE_LIST_REQUEST = "ATTENDANCE_LIST_REQUEST";
export const ATTENDANCE_LIST_SUCCESS = "ATTENDANCE_LIST_SUCCESS";
export const ATTENDANCE_LIST_FAIL = "ATTENDANCE_LIST_FAIL";

export const ATTENDANCE_REMOVE_REQUEST = "ATTENDANCE_REMOVE_REQUEST";
export const ATTENDANCE_REMOVE_SUCCESS = "ATTENDANCE_REMOVE_SUCCESS";
export const ATTENDANCE_REMOVE_FAIL = "ATTENDANCE_REMOVE_FAIL";
export const ATTENDANCE_REMOVE_RESET = "ATTENDANCE_REMOVE_RESET";

export const ATTENDANCE_ADD_REQUEST = "ATTENDANCE_ADD_REQUEST";
export const ATTENDANCE_ADD_SUCCESS = "ATTENDANCE_ADD_SUCCESS";
export const ATTENDANCE_ADD_FAIL = "ATTENDANCE_ADD_FAIL";
export const ATTENDANCE_ADD_RESET = "ATTENDANCE_ADD_RESET";

export const ATTENDANCE_ADD_EXTRA_REQUEST = "ATTENDANCE_ADD_EXTRA_REQUEST";
export const ATTENDANCE_ADD_EXTRA_SUCCESS = "ATTENDANCE_ADD_EXTRA_SUCCESS";
export const ATTENDANCE_ADD_EXTRA_FAIL = "ATTENDANCE_ADD_EXTRA_FAIL";
export const ATTENDANCE_ADD_EXTRA_RESET = "ATTENDANCE_ADD_EXTRA_RESET";

export const ATTENDANCE_MEMBER_LIST_REQUEST = "ATTENDANCE_MEMBER_LIST_REQUEST";
export const ATTENDANCE_MEMBER_LIST_SUCCESS = "ATTENDANCE_MEMBER_LIST_SUCCESS";
export const ATTENDANCE_MEMBER_LIST_FAIL = "ATTENDANCE_MEMBER_LIST_FAIL";
export const ATTENDANCE_MEMBER_LIST_RESET = "ATTENDANCE_MEMBER_LIST_RESET";
